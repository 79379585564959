<template>
  <div>
    <RouterView />
    <Notifications />
    <LoadOverlay />
    <ReloadPrompt />
  </div>
</template>

<script>
import { Notifications, LoadOverlay, ReloadPrompt } from '@/shared-vue'

export default {
  name: "App",
  components: {
    Notifications,
    LoadOverlay,
    ReloadPrompt
},
  created() {
    this.$store.commit('initializeStore')
    let googleClientId = document.querySelector('meta[name=google-signin-client_id]').getAttribute('content')
    if (googleClientId !== '{{ google_client_id }}' && googleClientId !== 'c') {
      this.$store.commit('auth/add_google_client_id', googleClientId)
    }
    this.$api.get(
      'accounts/login/',
      response => {
        if (response.authenticated) {
          this.$store.commit('auth/login_success', response.user)
        } else {
          this.$store.commit('auth/logout')
        }
      },
      true
    )
  }
}
</script>

<style>
@import "../node_modules/bulma/css/bulma.min.css";

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
</style>
