import apiStore from "@/shared-vue/apiStore"

export default {
  namespaced: true,
  mutations: {
    initialize() {
      this.commit('links/categories/initialize')
    }
  },
  modules: {
    categories: apiStore('links/api/categories/')
  }
}