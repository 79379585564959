const Base = () => import('@/components/overtime_tracker/Base')
const Home = () => import('@/components/overtime_tracker/Home')
const Days = () => import('@/components/overtime_tracker/Days')
const Configuration = () => import('@/components/overtime_tracker/Configuration')
const Process = () => import('@/components/overtime_tracker/Process')

export default [
  {
    path: '/overtime',
    component: Base,
    children: [
      { path: '', component: Home, name: 'overtime:home' },
      { path: 'days/', component: Days, name: 'overtime:days' },
      { path: 'configuration/', component: Configuration, name: 'overtime:configuration' },
      { path: 'process/', component: Process, name: 'overtime:process' },
    ]
  }
]
