const Base = () => import('@/components/groceries/Base')
const Home = () => import('@/components/groceries/Home')
const Recipes = () => import('@/components/groceries/Recipes')
const GroceryList = () => import('@/components/groceries/GroceryList')
const RecipeForm = () => import('@/components/groceries/RecipeForm')
const ItemManager = () => import('@/components/groceries/ItemManager')

export default [
  {
    path: '/groceries',
    component: Base,
    children: [
      { path: '', component: Home, name: 'groceries:home' },
      { path: 'recipes/', component: Recipes, name: 'groceries:recipes' },
      { path: 'recipes/add/', component: RecipeForm, name: 'groceries:add_recipe' },
      { path: 'recipes/:id/', component: RecipeForm, name: 'groceries:view_recipe' },
      { path: 'grocery-list/', component: GroceryList, name: 'groceries:grocery_list' },
      { path: 'item-manager/', component: ItemManager, name: 'groceries:item_manager' }
    ]
  }
]
