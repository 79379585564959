<template>
  <div v-show="googleClientId">
    <h2
      class="subtitle"
      style="margin-bottom: 0.4em"
    >
      Use Google to sign up or login
    </h2>
    <div id="google-signin-button"></div>
    <hr />
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  data() {
    return {
      tries: 0
    }
  },
  computed: {
    ...mapState({
      googleClientId: state => state.auth.google_client_id
    })
  },
  mounted() {
    if (this.googleClientId) {
      this.renderButton()
    }
  },
  watch: {
    googleClientId() {
      this.renderButton()
    }
  },
  methods: {
    onSignIn(response) {
      this.$store.dispatch(
        'auth/googleLogin',
        response.credential
      )
    },
    renderButton() {
      this.tries++
      if (window.google) {
        window.google.accounts.id.initialize({
          client_id: this.googleClientId,
          callback: this.onSignIn
        })
        window.google.accounts.id.renderButton(document.getElementById("google-signin-button"), { type: 'standard' })
      } else if (this.tries > 5) {
        return
      } else {
        setTimeout(this.renderButton, 200)
      }
    }
  }
}
</script>

