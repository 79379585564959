function shouldMerge(value) {
  return value !== null && typeof value === 'object' && !Array.isArray(value)
}

function getValue(appData, localData) {
  if (shouldMerge(appData)) {
    return deepmerge(appData, localData)
  } else {
    return localData
  }
}

function deepmerge(appData, localData) {
  let destination = {}
  Object.keys(appData).forEach(key => {
    if (key in localData) {
      destination[key] = getValue(appData[key], localData[key])
    } else {
      destination[key] = appData[key]
    }
  })
  return destination
}

export default deepmerge
