import api from '@/shared-vue/api'

/**
 * Construct the filter as the backends UrlQueryFilter expects it
 * @param {string} baseUrl 
 * @param {{
 *  type: 'ge' | 'lt' | 'contains' | 'sort',
 *  direction: 'desc' | 'asc',
 *  field: string, 
 *  term: string}[]} query
 * @returns 
 */
export function buildFilter(baseUrl, query) {
  return query.reduce((a, f) => {
    switch (f.type) {
      case 'ge':
        return a + `${f.field}=ge(${f.term})&`
      case 'lt':
        return a + `${f.field}=lt(${f.term})&`
      case 'contains':
        return a + `${f.field}=co(${f.term})&`
      case 'sort':
        return a + `${f.field}=sort(${f.direction})&`
      default:
        return a + `${f.field}=${f.term}&`
    }
  }, `${baseUrl}?`)
}

export default (apiEndpoint, cacheTime) => {
  return {
    namespaced: true,
    state: {
      cacheTime: cacheTime,
      lastRetrieved: null,
      url: apiEndpoint,
      loading: false,
      data: [],
      next: null,
      previous: null,
      count: null,
      searchResults: {
        data: [],
        next: null,
        previous: null,
        count: null,
      },
      aborter: null
    },
    getters: {
      getItem: (state) => (query) => {
        let result
        if (typeof query === 'number') {
          result = state.data.find(item => item.id === query)
        } else {
          result = state.data.find(item => {
            for (let field in query) {
              if (item[field] !== query[field]) {
                return false
              }
            }
            return true
          })
        }
        if (result === undefined) {
          return null
        }
        return result
      }
    },
    actions: {
      get(context, query, ignoreCache) {
        if (!ignoreCache && context.state.lastRetrieved !== null && Date.now() < (context.state.lastRetrieved + context.state.cacheTime)) {
          return
        }
        let url = query ? context.state.url + query : context.state.url
        context.commit('loading', true)
        api.get(url, response => {
          context.commit('set', response)
          context.commit('loading', false)
        })
      },
      getSingle(context, query) {
        if (typeof query === 'number') {
          return api.get(`${context.state.url}${query}/`, response => {
            context.commit('setSingle', response)
          }).fetch
        } else {
          let url = `${context.state.url}?`
          for (let field in query) {
            url = `${url}${field}=${query[field]}&`
          }
          return api.get(url, response => {
            if (response.length !== 0) {
              context.commit('setSingle', response[0])
            }
          }).fetch
        }
      },
      nextPage(context) {
        api.get(context.state.next, response => {
          context.commit('set', response)
        })
      },
      previousPage(context) {
        api.get(context.state.previous, response => {
          context.commit('set', response)
        })
      },
      /*  query = [
            {
              field: "release_year"
              term: "2000",
              type: "ge"
            } 
          ] 
      */
      search(context, query) {
        if (context.state.aborter?.abort !== undefined) {
          context.state.aborter.abort()
        }
        const url = buildFilter(context.state.url, query)
        const fetchRequest = api.get(url, response => {
          context.commit('setSearchResults', response)
        })
        context.commit('setAborter', fetchRequest.aborter)
        return fetchRequest.fetch
      },
      nextSearchPage(context) {
        api.get(context.state.searchResults.next, response => {
          context.commit('setSearchResults', response)
        })
      },
      previousSearchPage(context) {
        api.get(context.state.searchResults.previous, response => {
          context.commit('setSearchResults', response)
        })
      }
    },
    mutations: {
      loading(state, loading) {
        state.loading = loading
      },
      clearAll(state) {
        state.data = []
        state.next = null
        state.previous = null
        state.count = null
        state.searchResults = {
          data: [],
          next: null,
          previous: null,
          count: null,
        }
        state.aborter = null
      },
      initialize(state) {
        state.aborter = null
        state.searchResults = {
          data: [],
          next: null,
          previous: null,
          count: null,
        }
        state.url = apiEndpoint
      },
      setAborter(state, aborter) {
        state.aborter = aborter
      },
      set(state, data) {
        state.lastRetrieved = Date.now()
        if (Array.isArray(data)) {
          state.data = data
        } else {
          state.data = data.results
          state.next = data.next
          state.previous = data.previous
          state.count = data.count
        }
      },
      setSingle(state, item) {
        for (let i = 0; i < state.data.length; i++) {
          if (state.data[i].id === item.id) {
            state.data[i] = item
            return
          }
        }
        state.data.push(item)
      },
      setSearchResults(state, data) {
        if (Array.isArray(data)) {
          state.searchResults.data = data
        } else {
          state.searchResults.data = data.results
          state.searchResults.next = data.next
          state.searchResults.previous = data.previous
          state.searchResults.count = data.count
        }
      },
      remove(state, id) {
        state.data = state.data.filter(d => d.id !== id)
      },
      add(state, obj) {
        state.data.push(obj)
      },
      update(state, obj) {
        for (let i = 0; i < state.data.length; i++) {
          if (obj.id === state.data[i].id) {
            state.data[i] = obj
            return
          }
        }
      }
    }
  }
}
