const Base = () => import('@/components/home/Base')
const HomePage = () => import('@/views/home/HomePage')
const About = () => import('@/views/home/About')
const Bye = () => import('@/components/home/Bye')
const BlogFeed = () => import('@/views/home/BlogFeed')
const BlogArticle = () => import('@/views/home/BlogArticle')
import AccountRoutes from '@/shared-vue/router/AccountRoutes'

export default [
  {
    path: '/',
    component: Base,
    children: [
      { path: '', component: HomePage, name: 'ps:home' },
      { path: 'about/', component: About, name: 'ps:about' },
      { path: 'blog/feed/', component: BlogFeed, name: 'ps:blog:feed', props: route => ({ tag: route.query.tag, pageNumber: parseInt(route.query.p) }) },
      { path: 'blog/articles/:slug/', component: BlogArticle, name: 'ps:blog:article' },
      { path: 'bye/', component: Bye, name: 'ps:bye' },
      ...AccountRoutes({ backLocation: { name: 'ps:home' } })
    ]
  }
]
