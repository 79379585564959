<template>
  <div class="field">
    <label
      v-if="label"
      class="label"
      :for="inputId"
    >{{ label }}</label>
    <div
      class="control"
      :class="{ 'is-loading': isLoading }"
    >
      <input
        v-bind="$attrs"
        :id="inputId"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        class="input"
        :class="{ 'is-danger': hasError, 'is-small': isSmall }"
        type="text"
        :disabled="disabled"
      />
    </div>
    <p
      class="help is-danger"
      v-if="hasError"
    >{{ error[0] }}</p>
    <p
      class="help"
      v-if="help"
    >{{ help }}</p>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    /** The input value */
    modelValue: String,
    /** Label for the field */
    label: String,
    /** Array of error messages */ 
    error: Object,
    /** When true, adds a spinner to the input */
    isLoading: Boolean, 
    /** ID assigned to the input if present, otherwise uses the label */
    htmlId: String,
    /** Add is-small class on the input */
    isSmall: Boolean,
    /** Shows as a p tag under the input */
    help: String,
    /** Disables the input */
    disabled: Boolean,
  },
  computed: {
    hasError() {
      if (!this.error) {
        return false
      }
      if (this.error.length === 0) {
        return false
      }
      return true
    },
    inputId() {
      if (this.htmlId === undefined) {
        if (this.label === undefined) {
          return null
        }
        return this.label.replaceAll(" ", "-").toLowerCase()
      } else {
        return this.htmlId
      }
    }
  }
}
</script>