import apiStore from "@/shared-vue/apiStore"

export default {
  namespaced: true,
  mutations: {
    initialize() {
      this.commit('quiz/quizzes/initialize')
    }
  },
  modules: {
    quizzes: apiStore('quiz/api/quiz/'),
    questions: apiStore('quiz/api/questions/'),
    rounds: apiStore('quiz/api/rounds/')
  }
}