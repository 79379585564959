import { createRouter, createWebHashHistory } from 'vue-router'

import HomeRoutes from '@/router/HomeRoutes.js'
import OvertimeRoutes from '@/router/OvertimeRoutes.js'
import VariousRoutes from '@/router/VariousRoutes.js'
import GroceriesRoutes from '@/router/GroceriesRoutes.js'
import FinancesRoutes from '@/router/FinancesRoutes.js'
import LinksRoutes from '@/router/LinksRoutes.js'
import QuizRoutes from '@/router/QuizRoutes.js'

export default createRouter({
  history: createWebHashHistory(),
  routes: [
    ...HomeRoutes,
    ...OvertimeRoutes,
    ...VariousRoutes,
    ...GroceriesRoutes,
    ...FinancesRoutes,
    ...LinksRoutes,
    ...QuizRoutes,
  ]
})
