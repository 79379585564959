import apiStore from "@/shared-vue/apiStore"

export default {
  namespaced: true,
  mutations: {
    initialize() {
      this.commit('finances/datasets/initialize')
      this.commit('finances/searchTerms/initialize')
      this.commit('finances/categories/initialize')
      this.commit('finances/transactions/initialize')
      this.commit('finances/investmentAccounts/initialize')
      this.commit('finances/investmentSecurities/initialize')
    }
  },
  modules: {
    datasets: apiStore('finances/api/datasets/'),
    searchTerms: apiStore('finances/api/search-terms/'),
    categories: apiStore('finances/api/categories/'),
    transactions: apiStore('finances/api/transactions/'),
    balanceItems: apiStore('finances/api/balance-items/'),
    investmentTransactions: apiStore('finances/api/investment-transactions/'),
    amortizeItems: apiStore('finances/api/amortize-items/'),
    investmentAccounts: apiStore('finances/api/investments/accounts/'),
    investmentSecurities: apiStore('finances/api/investments/securities/'),
  }
}
