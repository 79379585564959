const applicationJson = 'application/json'

export default {
  notify: null,
  get(url, processingFunction, showLoading = false, catchCallback = (r) => this._httpStatusCodeErrorHandler(r)) {
    return this._fetch(
      url,
      {
        credentials: this._credentials,
        headers: new Headers(this.extraHeaders)
      },
      processingFunction,
      showLoading,
      catchCallback
    )
  },

  post(url, data, processingFunction, showLoading = false, catchCallback = (r) => this._httpStatusCodeErrorHandler(r)) {
    for (let key in data) {
      if (data[key] instanceof FileList || data[key] instanceof Blob) {
        return this.postFiles(url, data, processingFunction, showLoading, catchCallback)
      }
    }
    return this._fetch(
      url,
      {
        credentials: this._credentials,
        method: 'POST',
        headers: this._headers(),
        body: JSON.stringify(data)
      },
      processingFunction,
      showLoading,
      catchCallback
    )
  },

  postFiles(url, data, processingFunction, showLoading = false, catchCallback = (r) => this._httpStatusCodeErrorHandler(r)) {
    let formData = new FormData()
    for (let key in data) {
      if (data[key] instanceof FileList) {
        for (let file of data[key]) {
          formData.append(key, file)
        }
      } else if (data[key] instanceof Blob) {
        if (data[key].type.includes("audio/mpeg")) {
          formData.append(key, data[key], "audio.mp3")
        } else if (data[key].type.includes("audio/webm")) {
          formData.append(key, data[key], "audio.webm")
        } else if (data[key].type.includes("audio/ogg")) {
          formData.append(key, data[key], "audio.ogg")
        } else {
          formData.append(key, data[key])
        }
      } else {
        formData.append(key, data[key])
      }
    }
    return this._fetch(
      url,
      {
        credentials: this._credentials,
        method: 'POST',
        headers: new Headers({
          ...this.extraHeaders,
          'X-CSRFToken': this.getCsrfToken(),
          Accept: applicationJson
        }),
        body: formData
      },
      processingFunction,
      showLoading,
      catchCallback
    )
  },

  put(url, data, processingFunction, showLoading = false, catchCallback = (r) => this._httpStatusCodeErrorHandler(r)) {
    return this._fetch(
      url,
      {
        credentials: this._credentials,
        method: 'PUT',
        headers: this._headers(),
        body: JSON.stringify(data)
      },
      processingFunction,
      showLoading,
      catchCallback
    )
  },

  patch(url, data, processingFunction, showLoading = false, catchCallback = (r) => this._httpStatusCodeErrorHandler(r)) {
    for (let key in data) {
      if (data[key] instanceof FileList || data[key] instanceof Blob) {
        return this.patchFiles(url, data, processingFunction, showLoading, catchCallback)
      }
    }
    return this._fetch(
      url,
      {
        credentials: this._credentials,
        method: 'PATCH',
        headers: this._headers(),
        body: JSON.stringify(data)
      },
      processingFunction,
      showLoading,
      catchCallback
    )
  },

  patchFiles(url, data, processingFunction, showLoading = false, catchCallback = (r) => this._httpStatusCodeErrorHandler(r)) {
    let formData = new FormData()
    for (let key in data) {
      if (data[key] instanceof FileList) {
        for (let file of data[key]) {
          formData.append(key, file)
        }
      } else if (data[key] instanceof Blob) {
        if (data[key].type.includes("audio/mpeg")) {
          formData.append(key, data[key], "audio.mp3")
        } else if (data[key].type.includes("audio/webm")) {
          formData.append(key, data[key], "audio.webm")
        } else if (data[key].type.includes("audio/ogg")) {
          formData.append(key, data[key], "audio.ogg")
        } else {
          formData.append(key, data[key])
        }
      } else {
        formData.append(key, data[key])
      }
    }
    return this._fetch(
      url,
      {
        credentials: this._credentials,
        method: 'PATCH',
        headers: new Headers({
          ...this.extraHeaders,
          'X-CSRFToken': this.getCsrfToken(),
          Accept: applicationJson
        }),
        body: formData
      },
      processingFunction,
      showLoading,
      catchCallback
    )
  },

  delete(url, processingFunction, showLoading = false, catchCallback = (r) => this._httpStatusCodeErrorHandler(r)) {
    return this._fetch(
      url,
      {
        credentials: this._credentials,
        method: 'DELETE',
        headers: new Headers({
          ...this.extraHeaders,
          'X-CSRFToken': this.getCsrfToken()
        })
      },
      processingFunction,
      showLoading,
      catchCallback
    )
  },

  deleteWithData(url, data, processingFunction, showLoading = false, catchCallback = (r) => this._httpStatusCodeErrorHandler(r)) {
    return this._fetch(
      url,
      {
        credentials: this._credentials,
        method: 'DELETE',
        headers: this._headers(),
        body: JSON.stringify(data)
      },
      processingFunction,
      showLoading,
      catchCallback
    )
  },

  _fetch(url, options, processingFunction, showLoading, httpStatusCodeErrorHandler) {
    if (url === undefined || url === null) {
      throw Error('url is undefined or null')
    }
    let aborter = new AbortController()
    options.signal = aborter.signal
    if (showLoading) {
      this._showLoading()
    }
    if (!url.startsWith('http')) {
      url = import.meta.env.VITE_APP_API_URL + url
    }
    return {
      fetch: fetch(url, options)
        .then(response => {
          if (showLoading) {
            this._cleanLoading()
          }
          if (!response.ok) {
            httpStatusCodeErrorHandler(response)
            return
          }
          if (response.status === 204) {
            return processingFunction({})
          }
          return response.json().then(processingFunction)
        })
        .catch(err => {
          if (showLoading) {
            this._cleanLoading()
          }
          if (err.name === 'AbortError') {
            return
          } else {
            this._technicalError(err)
            console.error(err)
          }
        }),
      aborter
    }
  },

  _technicalError(err) {
    this.notify({
      text: `A technical error occurred\n${err}`,
      style: 'error',
      duration: 5000
    })
  },

  _httpStatusCodeErrorHandler(response) {
    const self = this
    response.text().then(responseText => {
      if (responseText.length >= 300) {
        responseText = responseText.substring(0, 300) + " (...)"
      }
      self.notify({
        text: `A functional error occurred\n${responseText}`,
        style: 'error',
        duration: 5000
      })
    })
  },

  _showLoading() {
    this.notify({
      loading: true
    })
  },

  _cleanLoading() {
    this.notify({
      loading: false
    })
  },

  _credentials: import.meta.env.VITE_APP_API_URL === '/' ? 'same-origin' : 'include',

  _headers() {
    return new Headers({
      ...this.extraHeaders,
      'X-CSRFToken': this.getCsrfToken(),
      Accept: applicationJson,
      'content-type': applicationJson
    })
  },

  extraHeaders: {},

  getCsrfToken() {
    return document.cookie.replace(/(?:(?:^|.*;\s*)csrftoken\s*\=\s*([^;]*).*$)|^.*$/, '$1')
  }
}