const Quizzes = () => import('@/views/quiz/Quizzes')
const ManageQuiz = () => import('@/views/quiz/ManageQuiz')
const Participate = () => import('@/views/quiz/Participate')
const QuizScores = () => import('@/views/quiz/QuizScores')

export default [
  {
    path: '/quiz',
    component: Quizzes,
    name: 'quiz:home'
  },
  {
    path: '/quiz/:id/manage',
    component: ManageQuiz,
    name: 'quiz:manage'
  },
  {
    path: '/quiz/:id/participate',
    component: Participate,
    name: 'quiz:participate'
  }
  ,
  {
    path: '/quiz/:id/score',
    component: QuizScores,
    name: 'quiz:score'
  }
]