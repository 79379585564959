export default function (store) {
  return (options) => {
    if (options.loading === true) {
      store.commit('notify/loading')
    } else if (options.loading === false) {
      store.commit('notify/loadingDone')
    } else if (!options.hasOwnProperty('style')) {
      console.warn(`Old notify was used, title: ${options.title}`)
    } else if (!['success', 'warn', 'error'].includes(options.style)) {
      console.warn(`style should be one of success, warn or error, but was: ${options.style}`)
    } else if (!options.text) {
      console.warn('Options should include text')
    } else {
      if (!options.hasOwnProperty('duration')) {
        options.duration = 2000
      } else if (isNaN(options.duration) || options.duration < 500) {
        console.warn(`Notify duration < 500 is not allowed: ${options.duration}`)
        options.duration = 2000
      }
      store.commit('notify/popup', options)
    }
  }
}