<template>
  <form
    class="login-root"
    @submit.prevent
  >
    <h2
      v-if="showTitle"
      class="subtitle"
      style="margin-top: 0.5em; margin-bottom: 0.5em"
    >
      Login/Register
    </h2>

    <GoogleLogin />

    <TextInput
      v-model="form.username"
      label="Username"
    />
    <button
      type="submit"
      id="check-user"
      class="button is-info"
      @click="checkUser"
      v-if="!userChecked"
    >
      Next
    </button>

    <div v-if="register">
      <p class="content notification">
        {{ form.username }} is niet bekend, wil je een account aanmaken?<br />
        <slot name="disclaimer" />
      </p>
      <button
        type="button"
        id="register"
        class="button is-info"
        @click="webauthnRegister"
      >
        Account aanmaken
      </button>
    </div>

    <div v-if="google && !webauthn && !password">
      <p class="content">Log in met Google door op de knop hierboven te drukken.</p>
    </div>

    <div v-if="webauthn">
      <p class="content">Beantwoord de popup...</p>
    </div>

    <div v-if="password && !webauthn">
      <div class="field">
        <label
          for="password"
          class="label"
        >Password</label>
        <div class="control">
          <input
            v-model="form.password"
            class="input"
            type="password"
            name="password"
            id="password"
            required
          />
        </div>
      </div>
      <div class="buttons">
        <button
          type="submit"
          id="password-login"
          class="button is-info"
          @click="passwordLogin"
        >
          Login
        </button>
        <RouterLink
          :to="{ name: 'accounts:add-new-device' }"
          class="button is-outlined"
        >Dit apparaat toevoegen</RouterLink>
      </div>
      <div
        class="notification is-danger"
        v-if="authError"
        style="margin-top: 1em"
      >
        Login failed
      </div>
    </div>

    <div v-if="!password && webauthnFailed">
      <div class="notification">Inlog met webauthn mislukt</div>
      <p class="mb-4">Is dit een nieuw apparaat en wil je hem toevoegen?</p>
      <RouterLink
        :to="{ name: 'accounts:add-new-device' }"
        class="button"
      >Dit apparaat toevoegen</RouterLink>
    </div>
  </form>
</template>

<script>
import TextInput from '../inputs/TextInput.vue'
import { create, get, parseCreationOptionsFromJSON, parseRequestOptionsFromJSON } from "@github/webauthn-json/browser-ponyfill"
import { mapState } from "vuex"
import GoogleLogin from "./GoogleLogin.vue"

export default {
  props: {
    showTitle: {
      type: Boolean,
      default: true
    }
  },
  components: {
    GoogleLogin,
    TextInput
  },
  data() {
    return {
      form: {
        username: "",
        password: ""
      },
      userChecked: false,
      password: false,
      register: false,
      google: false,
      webauthn: false,
      webauthnFailed: false,
    }
  },
  computed: mapState({
    authError: state => state.auth.authError,
  }),
  watch: {
    'form.username'() {
      this.userChecked = false
      this.password = false
      this.register = false
      this.webauthn = false
      this.google = false
    }
  },
  methods: {
    passwordLogin() {
      this.$store.dispatch("auth/passwordLogin", this.form)
    },
    checkUser() {
      if (this.form.username === "") {
        return
      }
      this.$api.post('accounts/check-user/', { username: this.form.username }, (response) => {
        if (response.result.includes('unknown')) {
          this.register = true
        }
        if (response.result.includes('password')) {
          this.password = true
          if (!response.result.includes('webauthn')) {
            this.$nextTick(() => {
              document.getElementById("password").focus()
            })
          }
        }
        if (response.result.includes('webauthn')) {
          this.webauthn = true
          this.$nextTick(() => this.webauthnLogin())
        }
        if (response.result.includes('google')) {
          this.google = true
        }
        this.userChecked = true
      })
    },
    webauthnLogin() {
      this.$api.post('webauthn/api/start-auth/', { username: this.form.username }, async (authOptions) => {
        try {
          const authCred = await get(parseRequestOptionsFromJSON({ publicKey: authOptions }))
          this.$api.post('webauthn/api/auth/', authCred, () => {
            this.whenAuthenticated()
          }, false, err => {
            this.$notify({
              style: 'error',
              text: 'Inloggen mislukt'
            })
            this.webauthnFailed = true
            this.webauthn = false
          })
        } catch {
          this.webauthnFailed = true
          this.webauthn = false
        }
      })
    },
    webauthnRegister() {
      this.$api.post('webauthn/api/start-registration/', { username: this.form.username }, async (registerOptions) => {
        try {
          const credential = await create(parseCreationOptionsFromJSON({ publicKey: registerOptions }))
          this.$api.post(`webauthn/api/register/`, credential, (data) => {
            this.whenAuthenticated()
          })
        } catch {
          this.$notify({
            style: 'error',
            text: 'Registratie mislukt'
          })
          this.form.username = ''
        }
      })
    },
    whenAuthenticated() {
      this.$api.get(
        'accounts/login/',
        response => {
          if (response.authenticated) {
            this.$store.commit('auth/login_success', response.user)
          } else {
            this.$store.commit('auth/logout')
          }
        },
        true
      )
    }
  }
}
</script>
