
import { createStore } from 'vuex'
import Auth from '@/shared-vue/authStore'
import Links from './links'
import Overtime from "./overtime"
import Groceries from "./groceries"
import Finances from "./finances"
import deepmerge from './deepmerge'
import Quiz from './quiz'
import notificationStore from '@/shared-vue/notifications/store'

let offlineStore = function (store) {
  store.subscribe((mutation, state) => {
    localStorage.setItem('state', JSON.stringify(state))
  })
}

export default createStore({
  plugins: [offlineStore],
  mutations: {
    initializeStore() {
      let data = localStorage.getItem('state')
      if (data) {
        data = deepmerge(this.state, JSON.parse(data))
        this.replaceState(Object.assign(this.state, data))
      }
      this.commit('links/initialize')
      this.commit('overtime/initialize')
      this.commit('groceries/initialize')
      this.commit('finances/initialize')
    }
  },
  modules: {
    auth: Auth,
    links: Links,
    overtime: Overtime,
    groceries: Groceries,
    finances: Finances,
    notify: notificationStore,
    quiz: Quiz
  }
})
