import apiStore from "@/shared-vue/apiStore"

export default {
  namespaced: true,
  mutations: {
    initialize() {
      this.commit('overtime/days/initialize')
    }
  },
  modules: {
    days: apiStore('overtime/api/days/'),
    breaktimes: apiStore('overtime/api/breaktimes/'),
  }
}