import { createApp } from 'vue'
import App from './App.vue'
import i18n from './i18n'
import router from './router'
import api from './shared-vue/api'
import notify from './shared-vue/notifications/notify'
import store from './store'

const app = createApp(App)
app.use(router)
app.use(store)
app.use(i18n)

const notifier = notify(store)
api.notify = notifier

app.config.globalProperties.$api = api
app.config.globalProperties.$notify = notifier

const dutchDateTimeFormatter = Intl.DateTimeFormat('nl-NL', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })
const dutchMonthYearFormatter = Intl.DateTimeFormat('nl-NL', { year: 'numeric', month: 'long' })
const dateFormatter = Intl.DateTimeFormat('nl-NL', { year: 'numeric', month: '2-digit', day: '2-digit' })

app.config.globalProperties.$filters = {
  currency(value, na) {
    if (na && !value) return 'NA'
    if (!value) value = 0
    value = Number(value)
    return value.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' })
  },
  dutchDateTime(value) {
    return dutchDateTimeFormatter.format(new Date(value))
  },
  dutchMonthYear(value) {
    return dutchMonthYearFormatter.format(new Date(value))
  },
  date(value) {
    return dateFormatter.format(new Date(value))
  }
}

app.mount("#app")