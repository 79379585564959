const Base = () => import('@/components/finances/Base')
const Dashboard = () => import('@/components/finances/Dashboard')
const Transactions = () => import('@/components/finances/Transactions')
const Categories = () => import('@/components/finances/Categories')
const SearchTerms = () => import('@/components/finances/SearchTerms')
const Balances = () => import('@/components/finances/Balances')
const Processing = () => import('@/components/finances/Processing')
const YearTable = () => import('@/components/finances/YearTable')
const Budget = () => import('@/components/finances/Budget')
const Reports = () => import('@/components/finances/Reports')
const Investments = () => import('@/views/finances/Investments')
const InvestmentAccount = () => import('@/views/finances/InvestmentAccount')
const ProcessingTimestamps = () =>
  import('@/components/finances/ProcessingTimestamps')
const AmortizeItems = () => import('@/views/finances/AmortizeItems')

export default [
  {
    path: '/finances',
    component: Base,
    children: [
      { path: '', component: Dashboard, name: 'finances:dashboard' },
      { path: 'transactions/', component: Transactions, name: 'finances:transactions' },
      { path: 'categories/', component: Categories, name: 'finances:categories' },
      { path: 'search-terms/', component: SearchTerms, name: 'finances:search-terms' },
      { path: 'balances/', component: Balances, name: 'finances:balances' },
      { path: 'processing/', component: Processing, name: 'finances:processing' },
      { path: 'reports/', component: Reports, name: 'finances:reports' },
      { path: 'year-table/', component: YearTable, name: 'finances:year-table' },
      { path: 'budget/', component: Budget, name: 'finances:budget' },
      { path: 'investment/:id/', component: InvestmentAccount, name: 'finances:investment-account' },
      { path: 'investment/', component: Investments, name: 'finances:investments' },
      { path: 'processing-timestamps', component: ProcessingTimestamps, name: 'finances:processing-timestamps' },
      { path: 'amortize-items/', component: AmortizeItems, name: 'finances:amortize-items' }
    ]
  }
]
