<template>
  <div
    v-if="loading"
    class="loading-screen"
  >
    <div class="lds-dual-ring"></div>
  </div>
</template>

<script>
import { mapState } from "vuex"

/** Shows an spinner overlay when state.notify.loading is true. */
export default {
  computed: {
    ...mapState({
      loading: state => state.notify.loading
    }),
  }
}
</script>

<style scoped>
.loading-screen {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
  z-index: 1001;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
  z-index: 1001;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>