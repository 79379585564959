const Base = () => import('@/components/links/Base')
const Home = () => import('@/components/links/Home')
const Admin = () => import('@/components/links/Admin')

export default [
  {
    path: '/links',
    component: Base,
    children: [
      { path: '', component: Home, name: 'links:home' },
      { path: 'admin/', component: Admin, name: 'links:admin' }
    ]
  }
]
