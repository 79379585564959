import api from './api'

export default {
  namespaced: true,
  state: {
    authenticated: null,
    user: null,
    google_client_id: null,
    authError: false,
  },
  actions: {
    refreshUserData(context) {
      api.get(
        "accounts/login/",
        response => {
          if (response.authenticated) {
            context.commit("login_success", response.user)
          } else {
            context.commit("logout")
          }
        }
      )
    },
    passwordLogin(context, form) {
      api.post(
        "accounts/login/",
        form,
        response => {
          if (response.authenticated) {
            context.commit("login_success", response.user)
          } else {
            context.commit("login_failed")
          }
        }
      )
    },
    googleLogin(context, id_token) {
      api.post(
        "accounts/google-login/",
        { id_token: id_token },
        response => {
          if (response.authenticated) {
            context.commit('login_success', response.user)
          }
        })
    },
    logout(context) {
      api.get('accounts/logout/', _ => {
        context.commit("logout")
      })
    }
  },
  mutations: {
    add_google_client_id(state, id) {
      state.google_client_id = id
    },
    login_success(state, user) {
      state.authenticated = true
      state.user = user
      state.authError = false
      const next = new URLSearchParams(window.location.search).get("next")
      if (next !== null) {
        window.location.replace(next)
      }
    },
    login_failed(state) {
      state.authenticated = false
      state.user = null
      state.authError = true
    },
    logout(state) {
      state.authenticated = false
      state.user = null
    },
    new_email(state, email) {
      state.user.email = email
    },
    new_username(state, username) {
      state.user.username = username
    }
  }
}