export default {
  namespaced: true,
  state: {
    popup: {
      style: null,
      text: null,
      duration: null,
    },
    loading: false
  },
  mutations: {
    popup(state, popup) {
      state.popup = popup
    },
    loading(state) {
      state.loading = true
    },
    loadingDone(state) {
      state.loading = false
    }
  }
}