import apiStore from "@/shared-vue/apiStore"

export default {
  namespaced: true,
  mutations: {
    initialize() {
      this.commit('groceries/recipes/initialize')
      this.commit('groceries/units/initialize')
      this.commit('groceries/items/initialize')
    }
  },
  modules: {
    recipes: apiStore('groceries/api/recipes/'),
    units: apiStore('groceries/api/units/'),
    items: apiStore('groceries/api/items/')
  }
}