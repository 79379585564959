const SudokuSolver = () => import('@/components/various/sudoku/SudokuSolver')
const Settlers = () => import('@/components/various/Settlers')
const RhythmTapper = () => import('@/components/various/RhythmTapper')
const Drinks = () => import('@/components/various/drinks/Drinks')

export default [
  { path: '/various/sudoku/', component: SudokuSolver, name: 'various:sudoku' },
  { path: '/various/settlers/', component: Settlers, name: 'various:settlers' },
  { path: '/various/rhythm-tapper/', component: RhythmTapper, name: 'various:rhythm-tapper' },
  { path: '/various/drinks/', component: Drinks, name: 'various:drinks' }
]
